import { useMemo } from 'react';

import type { GeneratorInputFieldType } from 'common/components/FieldGenerator/types';
import type { DefaultToFormAdaptersFieldType } from 'containers/FormDataAdapter/types';
import type { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';

import { IRangeMaxValidationDetails, IRangeMinValidationDetails, ValidationTypes } from 'common/types/fields';
import { LengthType } from 'common/types/additional';
import { isMaxValidationType, isMinValidationType } from 'utils/isMinMaxValidation';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { ROUNDING_MULTIPLIER } from './constants';


/** This only uses inches and centimeters as the length types, will fail at feet */
export const toFixedByLengthType = (value: number | string, lengthTypeFrom: LengthType, lengthTypeTo: LengthType) => {
  if (lengthTypeFrom === lengthTypeTo) {
    return Math.round(Number(value) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
  }
  if (lengthTypeTo === LengthType.centimeters) {
    return Math.round((Number(value) * 2.54) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
  }
  return Math.round((Number(value) / 2.54) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
};

export const MinValueAttr = 'min';
export const MaxValueAttr = 'max';

export const useTranslateAssemblyLengthToAppropriateLengthType = <TField = DefaultToFormAdaptersFieldType>(
  field: HydraparkProjectFields,
  fields: ConfiguredRecordType<HydraparkProjectFields, TField>,
  lengthType: LengthType,
) => useMemo(() => {
    if (fields[field]) {
      const adoptedALF = {
        ...fields[field],

      } as GeneratorInputFieldType; // we need hardcoded type for this field to support custom validation dependent on lengthType
      let minValValidatorOverride: number | undefined;
      let maxValValidatorOverride: number | undefined;
      // console.log('adoptedALF', adoptedALF.title, adoptedALF.value?.attributes);
      if (adoptedALF.value?.attributes?.[MinValueAttr]) {
        const evalMinValue = String(adoptedALF.value.attributes[MinValueAttr]);
        const numberValue = Number(evalMinValue.replace(/[^0-9.-]+/g, ''));
        if (!Number.isNaN(numberValue)) {
          minValValidatorOverride = numberValue;
        }
      }
      if (adoptedALF.value?.attributes?.[MaxValueAttr]) {
        const evalMaxValue = String(adoptedALF.value.attributes[MaxValueAttr]);
        const numberValue = Number(evalMaxValue.replace(/[^0-9.-]+/g, ''));
        if (!Number.isNaN(numberValue)) {
          maxValValidatorOverride = numberValue;
        }
      }
      // console.log('minmax', minValValidatorOverride, maxValValidatorOverride);
      if (adoptedALF.validation) {
        adoptedALF.validation = adoptedALF
          .validation
          .filter((validationObj) => !(validationObj as {temp?: unknown}).temp)
          .map((validationObj) => {
            switch (validationObj.type) {
              case ValidationTypes.range: {
                return {
                  ...validationObj,
                  ...(isMinValidationType(validationObj)
                    ? { minValue: toFixedByLengthType(minValValidatorOverride || validationObj.minValue, LengthType.inches, lengthType) }
                    : {}),
                  ...(isMaxValidationType(validationObj)
                    ? { maxValue: toFixedByLengthType(maxValValidatorOverride || validationObj.maxValue, LengthType.inches, lengthType) }
                    : {}),
                };
              }
              default:
                return validationObj;
            }
          });

        if (adoptedALF.name === HydraparkProjectFields.size_ID) {
          const OD = fields[HydraparkProjectFields.size_OD] as unknown as GeneratorInputFieldType;
          if (OD && OD.value?.selected?.value) {
            const ODValue = toFixedByLengthType(OD.value?.selected?.value, LengthType.inches, lengthType);
            const diff = toFixedByLengthType(OD.value?.attributes?.diff ?? 3, LengthType.inches, lengthType);

            const tempValidator1 = {
              type: ValidationTypes.range,
              maxValue: ODValue,
              promt: 'ID should be less than OD',
              temp: true,
            } as IRangeMaxValidationDetails;
            const tempValidator2 = {
              type: ValidationTypes.range,
              minValue: ODValue - diff,
              // eslint-disable-next-line max-len
              promt: lengthType === LengthType.inches ? `ID should not be less than ${diff} inches less than OD` : `ID should not be less than ${diff} cm less than OD`,
              temp: true,
            } as IRangeMinValidationDetails;

            adoptedALF.validation?.push(tempValidator1);
            adoptedALF.validation?.push(tempValidator2);

            // console.log(adoptedALF, OD.value?.selected?.value, Number(OD.value?.selected?.value), ODValue);
          }
        }

        if (adoptedALF.name === HydraparkProjectFields.size_OD) {
          const ID = fields[HydraparkProjectFields.size_ID] as unknown as GeneratorInputFieldType;
          if (ID && ID.value?.selected?.value) {
            const IDValue = toFixedByLengthType(ID.value?.selected?.value, LengthType.inches, lengthType);
            const diff = toFixedByLengthType(ID.value?.attributes?.diff ?? 3, LengthType.inches, lengthType);

            const tempValidator1 = {
              type: ValidationTypes.range,
              minValue: IDValue,
              promt: 'OD should be greater than ID',
              temp: true,
            } as IRangeMinValidationDetails;

            const tempValidator2 = {
              type: ValidationTypes.range,
              maxValue: IDValue + diff,
              // eslint-disable-next-line max-len
              promt: lengthType === LengthType.inches ? `OD should not be more than ${diff} inches more than ID` : `OD should not be more than ${diff} cm more than ID`,
              temp: true,
            } as IRangeMaxValidationDetails;

            adoptedALF.validation?.push(tempValidator1);
            adoptedALF.validation?.push(tempValidator2);
          }
        }

        if (adoptedALF.name === HydraparkProjectFields.size_seal_groove_diameter) {
          const ID = fields[HydraparkProjectFields.size_ID] as unknown as GeneratorInputFieldType;
          const OD = fields[HydraparkProjectFields.size_OD] as unknown as GeneratorInputFieldType;
          if (ID && ID.value?.selected?.value) {
            const IDValue = toFixedByLengthType(ID.value?.selected?.value, LengthType.inches, lengthType);

            const tempValidator1 = {
              type: ValidationTypes.range,
              minValue: IDValue,
              promt: 'Should be greater than ID',
              temp: true,
            } as IRangeMinValidationDetails;

            adoptedALF.validation?.push(tempValidator1);
          }
          if (OD && OD.value?.selected?.value) {
            const ODValue = toFixedByLengthType(OD.value?.selected?.value, LengthType.inches, lengthType);

            const tempValidator1 = {
              type: ValidationTypes.range,
              maxValue: ODValue,
              promt: 'Should be less than OD',
              temp: true,
            } as IRangeMaxValidationDetails;

            adoptedALF.validation?.push(tempValidator1);
          }
        }
      }
      if (adoptedALF.value?.selected) {
        const { value } = adoptedALF.value.selected;
        if (value) {
          adoptedALF.value = {
            ...adoptedALF.value,
            selected: {
              value: toFixedByLengthType(Number(value), LengthType.inches, lengthType),
            },
          };
        }
      }

      return adoptedALF;
    }
    return undefined;
  }, [fields, lengthType]);
