import rawPrintStyle from '@hydrapark/styles/raw-print-styles';
import { LengthType } from 'common/types/additional';
import { TranslationFn } from 'utils/useTranslateFn';
import { TranslationKey } from '@hydrapark/utils/text-translations';
import { Summary } from './useSummary';

export const ItemTemplate = ({
  title, image, label, className, description,
}: { title?: string, image?: string, className?: string, label: string, description?: string }) => `
   <div class="item ${className}">
    <a>${label}</a>
    <div>
      ${image ? `<img src="${image}" alt="" />` : ''}
      <div>${title}</div>
    </div>
    ${description ? `<div class="description">${description}</div>` : ''}
  </div>
`;

export const DocumentTemplate = ({
  product,
  parts,
  profile,
  notes,
  sizes,
  sealOrGroove,
}: Summary, quantity: number, t: TranslationFn<TranslationKey>) => {
  const items = [
    ItemTemplate({
      title: product?.title,
      description: product?.attributes?.description,
      label: t('texts.pdf.product'),
    }),
    ItemTemplate({
      title: profile?.title,
      image: profile?.attributes?.imagea,
      description: profile?.attributes?.description,
      label: t('texts.pdf.profile'),
    }),
    ItemTemplate({
      title: sealOrGroove,
      label: t('texts.pdf.sealOrGroove'),
    }),
    ...parts.map((part) => ItemTemplate({
      title: `${part.material?.title!}`,
      label: `${t('texts.pdf.material')} #${part.partNo}`,
    })),
    ...sizes.map((part) => ItemTemplate({
      label: `${part.title}`,
      title: part.unit === LengthType.inches ? (`${part.value}"`) : (`${part.value} cm`),
    })),
    notes ? ItemTemplate({ title: notes, label: t('texts.pdf.notes') }) : '',
    quantity ? ItemTemplate({ title: quantity.toFixed(0), label: t('texts.pdf.assemblyQty') }) : '',
  ];
  const img = t('variables.pdf.logo').startsWith('http') ? t('variables.pdf.logo') : `https://${window.location.host}${t('variables.pdf.logo')}`;

  const imageB = profile?.attributes?.imageb ? `<div class="measurments">
                  <img src="${profile?.attributes?.imageb}" />
              </div>` : '';

  return `<html>
    <head> 
        <style>
            ${rawPrintStyle}
        </style>
    </head>
    <body class="print">
        <div class="print-body">
            <div class="logo-wrapper">
                <img src="${img}" class="logo" />
            </div>
            <h1 class="header">${t('texts.pdf.title')}</h1>            
            <h1 class="subheader">${t('texts.pdf.subtitle')}</h1>         
            <div class="wrapper">
              ${imageB}  
              ${items.join('')}
              <p>${t('texts.pdf.tos')}</p>
            </div>
        </div>
    </body>
</html>`;
};
