import { DeepKeys } from 'utils/type-tools';

const customization = {
  /**
   * Exposed project specific variables like logos, email addresses, etc.
   */
  variables: {
    pdf: {
      logo: '/@hydrapark-logo-1.png',
    },
  },
  /**
   * CSS variable overrides
   * @see /src/styles/css-variables.scss
   */
  css: {
    // 'color-accent': 'red',
  },
  texts: {
    step1: {
      title: 'Select Product',
      tabName: 'Select Product Type',
      description: 'Please use the below options to select the product type you are looking for.',
    },
    step2: {
      title: 'Select Profile',
      tabName: 'Select Profile',
      description: 'Please use the below options to select the profile you are looking for.',
    },
    step3: {
      title: 'Select Material',
      tabName: 'Select Material',
      description: 'Please use the below drop-down option to select material you need.',
    },
    step4: {
      title: 'Measurements',
      tabName: 'Measurements',
      description: 'Please use the below inputs to specify the measurements you need.',
    },
    step5: {
      title: 'Confirm Your Assembly',
      tabName: 'Confirm Assembly',
      // eslint-disable-next-line max-len
      description: 'Please verify component selections, specifications, and assembly length for your application. Custom assemblies are non-returnable and non-refundable. To modify, click the item below.',
    },
    pdf: {
      title: 'Custom Seal',
      subtitle: 'Assembly Summary',
      // eslint-disable-next-line max-len
      tos: 'By submitting this order, you accept full responsibility for product use, including risks of damage, injury, or death. Verify component specifications before purchasing. Custom products are non-returnable and non-refundable. HydraPak Seals disclaims all warranties, express or implied, and limits liability to product replacement only. Misuse voids any claims. These terms are governed by Utah law. By placing this order, you agree to these terms.',
      product: 'Product Type',
      profile: 'Profile',
      notes: 'Notes',
      assemblyQty: 'Assembly Qty',
      id: 'ID',
      od: 'OD',
      material: 'Material',
      na: 'N.A.',
      sealOrGroove: 'Seal Or Groove',
    },
    nav: {
      profile: 'Profile',
      product: 'Product Type',
    },
    print: {
      // eslint-disable-next-line max-len
      agreement: 'Questions or concerns about your configured assembly? Please contact us via email or via our live chat where assembly experts are available to help. You can also generate PDF file of your assembly to attach to your email. We&apos;re happy to assist you.',
    },
  },
} as const;

export type Type = typeof customization;
export type Texts = DeepKeys<typeof customization>;

export default customization;
