import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import { FormLoader } from 'common/components/Loader';
import { LengthType } from 'common/types/additional';
import {
  STEP_PRODUCT,
  STEP_PROFILE,
  STEP_MATERIALS, STEP_DIMENSIONS,
} from '@hydrapark/containers/FormSwitcher/stepNames';
import { scrollToEmptyInput } from 'utils/scrollToInput';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { isFieldDisabled } from 'utils/isStatusDisabled';
import FieldGenerator from 'common/components/FieldGenerator';
import { FieldsType } from 'common/types/fields';
import { getCommitAction } from 'common/constants';
import { CommitAction } from 'utils/commit-action';
import { QuantityConnected } from './components/QuantityConnected';


import TosCheckboxConnected from './components/TosCheckboxConnected';

import styles from './styles.module.scss';
import { PrintSection } from './PrintSection';
import { useSummary } from './useSummary';
import { Item } from './Item';


const SummaryView = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  onStepChange,
  children,
  isNextDisabled,
  clearProgressError,
  isLoading: isCartLoading,
}: WithChildren<IViewProps<HydraparkProjectFields>>) => {
  const idForm = 'form-summary';
  const { error, isLoading } = useGenericFieldsDataAdopted<IFormValues<HydraparkProjectFields>>();
  const { getFieldsByName, updateFieldsState } = useGenericFieldsActionsAdopted<IFormValues<HydraparkProjectFields>>();

  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    getFieldState,
    watch,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    setError,
    resetField,
  } = useForm<IFormValues<HydraparkProjectFields>>({ defaultValues });

  const tosAgreed = watch('tosAgreed');

  useEffect(() => {
    if (tosAgreed) {
      scrollToEmptyInput();
    }
  }, [tosAgreed]);


  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues<HydraparkProjectFields>> = useCallback((data) => {
    if (tosAgreed) {
      onNextClickHandler();
    } else {
      setError('tosAgreed', { message: 'Please accept these terms' });
    }
  }, [onNextClickHandler, tosAgreed]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  const summary = useSummary();
  const {
    parts,
    product,
    profile,
    sizes,
    sealOrGroove,
  } = summary;

  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: HydraparkProjectFields,
    changedValue?: IFormValues<HydraparkProjectFields>[keyof IFormValues<HydraparkProjectFields>],
  ) => {
    if (clearProgressError) { clearProgressError(); }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  const inputNoteField = fields[HydraparkProjectFields.notes];
  const addToCartNodeField = fields[HydraparkProjectFields.usernotes];

  const isManualContact = !isFieldDisabled(addToCartNodeField);
  const manualContactValue = (addToCartNodeField?.value as any).attributes?.notes;

  const nextCanBeUsed = getCommitAction() !== CommitAction.DISABLED;

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
        className="print"
      >
        {(isLoading || isCartLoading) && <FormLoader />}
        <div className={styles.itemList}>
          <Item
            onClick={() => onStepChange(STEP_PRODUCT)}
            label="Product Type"
            title={product?.title}
            image={product?.attributes?.imagea}
            description={product?.attributes?.description}
          />
          <Item
            onClick={() => onStepChange(STEP_PROFILE)}
            label="Profile"
            title={profile?.title}
            image={profile?.attributes?.imagea}
            description={profile?.attributes?.description}
          />
          <Item
            onClick={() => onStepChange(STEP_PROFILE)}
            label="Seal Or Groove"
            title={sealOrGroove}
          />
          {parts.map((part, index) => (
            <Item
              key={part.material?.id}
              onClick={() => onStepChange(STEP_MATERIALS)}
              label={`Material #${index + 1}`}
              title={part.material?.title}
            />
          ))}
          {sizes.map((part) => (
            <Item
              key={part.title}
              onClick={() => onStepChange(STEP_DIMENSIONS)}
              label={`${part.title}`}
              title={part.unit === LengthType.inches ? (`${part.value}"`) : (`${part.value} cm`)}
            />
          ))}
        </div>
        <div className={styles.other}>
          {(
            inputNoteField
                && !isFieldDisabled(inputNoteField)
          ) && (
            <FieldGenerator<IFormValues<HydraparkProjectFields>>
              field={inputNoteField}
              name={inputNoteField.name as HydraparkProjectFields}
              control={control}
              watch={watch}
              setValue={setValue}
              getFieldState={getFieldState}
              key={inputNoteField.name}
              onChangeHandler={handleFieldUpdate}
              isDisabled={isFieldDisabled(inputNoteField)}
              resetField={resetField}
            />
          )}
          <div className={styles.quantity}>
            <QuantityConnected />
          </div>
        </div>
        {!isManualContact && (
          <div className={styles.tos}>
            <TosCheckboxConnected control={control} watch={watch} setValue={setValue} />
          </div>
        )}
        {isManualContact && (
          <div className={styles.manual}>
            {manualContactValue}
          </div>
        )}
        {children}
      </form>
      <div className={styles.desktop}>
        <ViewsButtonsBlock
          idForm={idForm}
          onResetClickHandler={onResetHandler}
          onBackClickHandler={onBackClickHandler}
          nextButtonText="texts.btn.complete"
          isNextDisabled={isManualContact || isNextDisabled || !tosAgreed || !nextCanBeUsed}
        />
      </div>
      <PrintSection summary={summary} />
      <div className={styles.mobile}>
        <ViewsButtonsBlock
          idForm={idForm}
          onResetClickHandler={onResetHandler}
          onBackClickHandler={onBackClickHandler}
          nextButtonText="texts.btn.complete"
          isNextDisabled={isManualContact || isNextDisabled || !tosAgreed || !nextCanBeUsed}
        />
      </div>
    </FormWrapper>
  );
};

// @ts-ignore
export default SummaryView;
