/**
 * WARNING: Do not rename this file, see config-override.js
 */

import rawPrintStyle from 'styles/common.scss';
import rawPrintStyleCustom from './styles.scss';

/* eslint-disable-next-line */
let styleBundle = `
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:wght@100..900&display=swap');

`;

if (Array.isArray(rawPrintStyle)) {
  styleBundle = rawPrintStyle.map((item) => {
    // eslint-disable-next-line
    const [name, content] = item;
    return content;
  }).join('\n\r');
}

if (Array.isArray(rawPrintStyleCustom)) {
  styleBundle += rawPrintStyleCustom.map((item) => {
    // eslint-disable-next-line
    const [name, content] = item;
    return content;
  }).join('\n\r');
}


export default styleBundle;
