import { ConfiguredRecordType, ISchemaDataStorage } from 'containers/GenericFieldsHandlingLayer/types';
import { GeneratorFieldType } from 'common/components/FieldGenerator/types';
import { ExtendedDataStorageType } from 'containers/AdditionalDataStorage/types';
import { LengthType } from 'common/types/additional';
import { toFixedByLengthType } from '@hydrapark/views/MeasurmentsView/utils';
import {
  getSingleNumberValue, getSingleOption, getSingleStringValue, isCPQIframeData, wrapData,
} from 'api/cart/utils';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { isGeneratorDropdownFieldType } from '../../utils/isGeneratorFieldType';

export const partVariables = [
  {
    material: HydraparkProjectFields.material1,
  },
  {
    material: HydraparkProjectFields.material2,
  },
  {
    material: HydraparkProjectFields.material3,
  },
];

const allSizeFields = [
  HydraparkProjectFields.size_ID,
  HydraparkProjectFields.size_OD,
  HydraparkProjectFields.size_Height,
  HydraparkProjectFields.size_groove_width,
  HydraparkProjectFields.size_seal_groove_diameter,
  HydraparkProjectFields.size_bearing_groove_width,
  HydraparkProjectFields.size_shoulder_thickness_1,
  HydraparkProjectFields.size_shoulder_thickness_2,
] as const;

export const mapProjectFieldsToProjectHydraparkCart = (
  fields: ConfiguredRecordType<HydraparkProjectFields, GeneratorFieldType>,
  additional: ExtendedDataStorageType,
) => {
  const productType = getSingleOption(fields.hpProductTypeAttribute?.value?.selected);
  const profile = getSingleOption(fields.hpProfileAttribute?.value?.selected);
  const notes = getSingleStringValue(fields.notes?.value?.selected);
  let sealOrGrooveTitle = '';
  const sealOrGroove = fields.sealorgroove;
  const sealOrGrooveSelected = getSingleOption(sealOrGroove?.value?.selected);
  if (isGeneratorDropdownFieldType(fields.sealorgroove) && sealOrGrooveSelected) {
    sealOrGrooveTitle = fields.sealorgroove.value?.options?.find((option) => option.id === (sealOrGrooveSelected as any).value)?.title || '';
  }
  const parts = partVariables.map((part, index) => ({
    partNo: index + 1,
    material: getSingleOption(fields[part.material]?.value?.selected)?.title,
  })).filter((part) => part.material);

  const sizes = allSizeFields.map((field) => ({
    name: fields[field]?.title,
    // eslint-disable-next-line max-len
    value: fields[field]?.value?.selected ? toFixedByLengthType(getSingleNumberValue(fields[field]?.value?.selected)!, LengthType.inches, additional.lengthType) : undefined,
    unit: additional.lengthType,
  })).filter((part) => !!part.value);

  // eslint-disable-next-line max-len
  const generatedConfigId = `${productType?.title}:${profile?.title}:${parts.map((part) => `${part.material}`).join(',')}:${sizes.map((part) => `${part.name}:${part.value}:${part.unit}`).join(',')}`;

  return {
    generatedConfigId,
    quantity: additional.configQuantity,
    productType: productType?.title,
    profile: profile?.title,
    parts,
    sizes,
    sealOrGroove: sealOrGrooveTitle,
    notes,
  };
};

export const sendDataToParentIframe = async (data: ISchemaDataStorage<HydraparkProjectFields>['fields'], additional: ExtendedDataStorageType) => {
  console.log('sendDataToParentIframe', wrapData(mapProjectFieldsToProjectHydraparkCart(data, additional))); // eslint-disable-line no-console
  if (window.self !== window.parent) {
    return new Promise<void>((resolve, reject) => {
      window.parent.postMessage(wrapData(mapProjectFieldsToProjectHydraparkCart(data, additional)), '*');

      const listenToResponse = (e: MessageEvent) => {
        if (isCPQIframeData(e.data)) {
          const { error, isLoading } = e.data;

          if (!isLoading) {
            if (!error) {
              resolve();
            } else {
              reject(new Error(error));
            }

            window.removeEventListener('message', listenToResponse);
          }
        }
      };

      window.addEventListener('message', listenToResponse);
    });
  }
  throw new Error('Can only send data to parent iframe being in iframe');
};
